import axios from 'axios';
export async function getRelationByKey(relationOid, token) {
    const res = await axios.get(`/api/odata/Relation(${relationOid})?$expand=Contracts`, getHeaders(token));
    try {
        return res.data;
    }
    catch {
        return res;
    }
}

export async function getNotes(relationOid, token) {
    const res = await axios.get(`/api/odata/Report?$filter=Contract/Relation/Oid eq ${relationOid}&$select=Remark,MaintenanceDate,DoneByName,QuotationSent,TaskId,MoreWorkNeeded,RemarkDone`, getHeaders(token));
    try {
        return res.data.value;
    }
    catch {
        return res;
    }
}

const getHeaders = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
};

