import React, { useState, useEffect } from 'react';
import Button from 'devextreme-react/button';
import { formatDate } from 'devextreme/localization';
import ValidationGroup from 'devextreme-react/validation-group';
import ScrollView from 'devextreme-react/scroll-view';

import { Notes, Note } from '../../../types/card-notes';

import './CardNotes.scss';

const Card = ({ note }: { note: Note }) => {
  return (
    <div className='note dx-card'>
      <div className='note-title'>
         <div>
           <span>Task ID: {note.taskId} Date:{formatDate(new Date(note.date), 'dd-MM-yyyy')} - {note.manager} </span>
        </div>
         <div>
          {note.quotationSent ? <i className='dx-icon-todo' /> : ''}
          <Button icon='overflow' stylingMode='text' />
        </div>
      </div>
      <div className='note-text'>{note.text}</div>
    </div>
  );
};

export const CardNotes = ({ items }: { items?: Notes; }) => {
  const [data, setData] = useState(items);

  useEffect(() => {
    setData(items);
  }, [items]);

  return (
    <ValidationGroup>
      <div className='notes'>
        <div className='messages-content'>
          <ScrollView>
            <div className='message-list'>
              {data?.map((note, index) => (
                <Card key={index} note={note} />
              ))}
            </div>
          </ScrollView>
        </div>
      </div>
    </ValidationGroup>
  );
};
