import axios, { AxiosError } from 'axios';
import type { User } from '../types';
//export async function signIn(email: string, password: string) {
//  try {
//    // Send request
//    return {
//      isOk: true,
//      data: { ...defaultUser, email },
//    };
//  } catch {
//    return {
//      isOk: false,
//      message: 'Authentication failed',
//    };
//  }
//}

/* eslint-disable @typescript-eslint/no-unused-vars */
export async function signIn(userName: string, password: string) {
    let data: User = { isOk: false };
    await axios.post('/api/Authentication/Authenticate', { userName, password }).then(res => {
      if (res.status === 200) {
          data = JSON.parse(JSON.stringify(res.data));
          localStorage.setItem('user', JSON.stringify(res.data));
        }
        else {
          localStorage.removeItem('user');
        }
    }).catch(error => {
        console.log(error);
        data = {
            isOk: false,
            message: error.response.data
        };
    });

    return data;
}

export async function refresh() {
    let userData: User = JSON.parse(localStorage.getItem('user')!);
    try {
        const res = await axios.post('/api/Authentication/Refresh', { accessToken: userData.token, refreshToken: userData.refreshToken });
        if (res.status === 200) {
            userData = JSON.parse(JSON.stringify(res.data));
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('token', res.data.token);
        }
        else {
            localStorage.removeItem('user');
        }
        return userData;
    }
    catch (e) {
        const error = e as AxiosError;
        return {
            isOk: false,
            message: error.message
        };
    }
}

export async function getUser() {
    let data: User = { isOk: false };
    try {
        // Send request
        data = JSON.parse(localStorage.getItem('user')!);
        return data;
    }
    catch {
        return data;
    }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    return {
      isOk: true,
      data: { email },
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}
