import React from 'react';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Form,
    Toolbar, Item as ToolbarItem,
    Lookup } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { withLoadPanel } from '../../../utils/withLoadPanel';
import './CardTasks.scss';
import DataSource from 'devextreme/data/data_source';
import Guid from 'devextreme/core/guid';
import 'devextreme/data/odata/store';
import { CONTRACTS_TYPE_LIST, CONTRACT_PERIOD_LIST } from '../../../shared/constants';
import { useAuth } from '../../../contexts/auth';
const Grid = ({ relationOid }: { relationOid: string }) => {
const { user } = useAuth();
const dataSource = new DataSource({
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Contract`,
            beforeSend: (e) => {
                e.headers = {
                    'Authorization': `Bearer ${user?.token}`
                };
            },
            onInserting: (values) => {
                values['RelationOid'] = relationOid;
            }
        },
        paginate: false,
        select: [
            'Oid',
            'ContractType',
            'ContractStartDate',
            'ContractEndPeriod',
            'LastMaintenanceDate',
            'LastMaintenanceDate1',
            'AutoRenewContract',
            'ContractPeriod'
        ],
    filter: ['Relation/Oid', '=', new Guid(relationOid)]
});

  return (
    <DataGrid
      className='tasks-grid'
      dataSource={dataSource}
      columnAutoWidth
      >
          <Toolbar>
              <ToolbarItem name='addRowButton' />
          </Toolbar>
      <Editing
        mode='popup'
        allowUpdating
        allowAdding
        allowDeleting>
            <Popup title='Relatie Info' showTitle width={700} height={525} />
            <Form>
                <Item itemType='group' colCount={2} colSpan={2}>
                <Item dataField='ContractType' editorType='dxSelectBox' />
                <Item dataField='AutoRenewContract' editorType='dxCheckBox' />
                <Item dataField='ContractStartDate' />
                <Item dataField='LastMaintenanceDate1' />
                <Item dataField='ContractPeriod' editorType='dxSelectBox' />
               </Item>
            </Form>
          </Editing>
    <Column
        visible={false}
        dataField='ContractPeriod'
        hidingPriority={3}>
        <Lookup dataSource={CONTRACT_PERIOD_LIST} />
     </Column>
      <Column
        dataField='ContractType'
        caption='Contract Type'
        hidingPriority={3}>
        <Lookup dataSource={CONTRACTS_TYPE_LIST} />
     </Column>
      <Column
        dataField='ContractStartDate'
        dataType='date'
        format='dd-MM-yyyy'
        caption='Contract startdatum'
        hidingPriority={1}
       />
      <Column
        format='dd-MM-yyyy'
        dataField='ContractEndPeriod'
        dataType='date'
        caption='Contract einddatum'
        hidingPriority={1}
          />
          <Column
              format='dd-MM-yyyy'
              dataField='LastMaintenanceDate'
              dataType='date'
              caption='Laatste onderhoud'
              hidingPriority={1}
          />
          <Column
              visible={false}
              format='dd-MM-yyyy'
              dataField='LastMaintenanceDate1'
              dataType='date'
              caption='Laatste onderhoud'
              hidingPriority={1}
          />
      <Column
        caption='Auto Contract Verlengen'
        dataField='AutoRenewContract'
        hidingPriority={0}
      />
    </DataGrid>
  );
};

const GridWithLoadPanel = withLoadPanel(Grid);
export const CardTasks = ({ relationOid, isLoading }: { relationOid?: string, isLoading: boolean }) => {
  return (
    <div className='card-tasks'>
          <GridWithLoadPanel
        relationOid={relationOid}
        hasData={!!relationOid}
        loading={isLoading}
        panelProps={{
            container: '.card-tasks',
            position: { of: '.card-tasks' }
        }}
      />
    </div>
  );
};
