import React, { useEffect, useState } from 'react';
import Form, { Item, ButtonItem, ButtonOptions, Label } from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../../../contexts/auth';
import Guid from 'devextreme/core/guid';
import { Contract } from '../../../types/crm-contact';
export const MoveMaintenance = ({ contractOid, onComplete }) => {
    const { user } = useAuth();
    //const form = useRef<Form>(null);
    const [contract, setContract] = useState<Contract | null>(null);
    const dataSource = new DataSource({
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Contract`,
            beforeSend: (e) => {
                e.headers = {
                    'Authorization': `Bearer ${user?.token}`
                };
            }
        },
        paginate: false,
        select: [
            'Oid',
            'ContractType',
            'ContractStartDate',
            'ContractEndPeriod',
            'LastMaintenanceDate',
            'LastMaintenanceDate1',
            'AutoRenewContract',
            'ContractPeriod'
        ],
        filter: ['Oid', '=', new Guid(contractOid)]
    });

    useEffect(() => {
        dataSource.load()
        .then(
            (data) =>
                {
                    if(data.length > 0)
                    {
                        setContract(data[0] as Contract);
                    }
                },
            (error) => { console.log(error); }
        );
    }, [contractOid]);

    const onSubmit = async(e) => {
        e.preventDefault();
        const store = dataSource.store();
        store.update(new Guid(contractOid), { LastMaintenanceDate1: contract?.LastMaintenanceDate1 })
        .then(
            () => {
                onComplete();
            },
            (error) => { console.log(error); }
        );
    };

  return (
    <form onSubmit={onSubmit}>
        <Form formData={contract}>
            <Item itemType='group'>
                <Item dataField='LastMaintenanceDate1' editorType='dxDateBox'>
                    <Label text='Laatste onderhoud' />
                </Item>
            </Item>
            <ButtonItem>
                <ButtonOptions
                    width='50%'
                    type='default'
                    useSubmitBehavior>
                    <span className='dx-button-text'>Opslaan</span>
                </ButtonOptions>
            </ButtonItem>
        </Form>
    </form>
  );
};
