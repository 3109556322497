import React from 'react';
import classNames from 'classnames';
import From, { Item as ItemForm, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { EmailRule } from 'devextreme-react/validator';
import NumberBox from 'devextreme-react/number-box';
import { FormTextbox } from '../../utils/form-textbox/FormTextbox';
import { Relation } from '../../../types/crm-contact';

export const ContactFromDetails = ({ data, editing, updateField }: {
    data: Relation, editing: boolean, updateField: (field: string | number) => (value: string | number) => void
}) => {
  return (
        <From
            className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
            labelMode='floating'
        >
            <GroupItem colCount={2}>
                <ColCountByScreen xs={2} />
                <GroupItem>
                    <ItemForm cssClass='accent'>
                        <NumberBox
                            label='Relatie Nr'
                            value={data.CustomerNumber}
                            readOnly={!editing}
                            elementAttr={{ class: 'form-editor' }}
                            inputAttr={{ class: 'form-editor-input' }}
                            stylingMode='filled'
                            onValueChange={updateField('CustomerNumber')}
                        />
                    </ItemForm>

                    <ItemForm>
                        <FormTextbox
                            label='Bedrijf'
                            value={data.Name}
                            isEditing={!editing}
                            onValueChange={updateField('Name')}
                        />
                    </ItemForm>

                    <ItemForm>
                        <FormTextbox
                            label='Contact Persoon'
                            value={data.ContactPerson}
                            isEditing={!editing}
                            onValueChange={updateField('ContactPerson')}
                        />
                    </ItemForm>
                </GroupItem>
                <ItemForm cssClass='accent' colSpan={2}>
                    <FormTextbox
                        label='Bedrijf'
                        value={data.Company}
                        isEditing={!editing}
                        onValueChange={updateField('Company')}
                    />
                </ItemForm>
            </GroupItem>

            <GroupItem colCount={4} caption='Contacts'>
                <ColCountByScreen xs={2} />
                <ItemForm colSpan={4}>
                    <FormTextbox
                        label='Adres'
                        value={data.Address}
                        isEditing={!editing}
                        onValueChange={updateField('Address')}
                    />
                </ItemForm>

                <ItemForm colSpan={2}>
                    <FormTextbox
                        label='Plaats'
                        value={data.City}
                        isEditing={!editing}
                        onValueChange={updateField('City')}
                    />
                </ItemForm>
              <ItemForm>
                  <FormTextbox
                      label='Postcode'
                      value={data.ZipCode}
                      isEditing={!editing}
                      onValueChange={updateField('ZipCode')}
                  />
              </ItemForm>
            </GroupItem>

            <GroupItem colCount={2} cssClass='contact-fields-group'>
                <ColCountByScreen xs={2} />
                <ItemForm>
                    <FormTextbox
                        label='Telefoon nummer'
                        value={data.TelephoneNumber}
                        isEditing={!editing}
                      onValueChange={updateField('TelephoneNumber')}
                    />
                </ItemForm>

              <ItemForm>
                    <FormTextbox
                        label='Email'
                        value={data.Email}
                        isEditing={!editing}
                        onValueChange={updateField('Email')}
                    >
                        <EmailRule />
                    </FormTextbox>
                </ItemForm>
            </GroupItem>
        </From>
    );
};
