import axios from 'axios';
export async function getMaintenancePlanning(token) {
    const res = await axios.get('/api/Planning/GetMaintenancePlanning', getHeaders(token));
    try {

        for (let i = 0; i < res.data.length; i++) {
                res.data[i].startDate = new Date(res.data[i].startDate);
                res.data[i].endDate = new Date(res.data[i].endDate);
        }
        return res.data;
    }
    catch {
        return res;
    }
}
export async function updateContract(token, contractOid, maintenancePlanned, lastMaintenanceDate) {
    return await axios.patch(`/api/odata/Contract(${contractOid})`, {
        LastMaintenanceDate: lastMaintenanceDate,
        MaintenancePlanned: maintenancePlanned,
    }, getHeaders(token));
}

export async function createOpdracht(tokenKlikPlatform, opdracht_omschrijving, opdracht_opmerkingen,
    opdracht_datum, werkzaamheden, status, medewerker, klant, opdracht_allday, visibleInApp, opdracht_einddatum, contractId) {

    return await axios.post(`${process.env.REACT_APP_WERKBONNEN_URL}/opdracht`, {
        opdracht_omschrijving, opdracht_opmerkingen,
        opdracht_datum, werkzaamheden, status, medewerker, klant, opdracht_allday, visibleInApp, opdracht_einddatum, contractId
    }, getWerkbonnenHeaders(tokenKlikPlatform));

}

export async function getMedewerkerList(tokenKlikPlatform) {
    const res = await axios.get(`${process.env.REACT_APP_WERKBONNEN_URL}/medewerkers/getMedewerkers/`, getWerkbonnenHeaders(tokenKlikPlatform));
    try {

        return res.data;
    }
    catch {
        return res;
    }
}

export async function getKlantenSimpel(tokenKlikPlatform) {
    const res = await axios.get(`${process.env.REACT_APP_WERKBONNEN_URL}/klant/findAllKlanten/`, getWerkbonnenHeaders(tokenKlikPlatform));
    try {
        return res.data;
    }
    catch {
        return res;
    }
}

const getHeaders = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
};

const getWerkbonnenHeaders = (tokenKlikPlatform) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenKlikPlatform}`
        }
    };
};
