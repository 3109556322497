import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid, { Button, Column, Sorting, Selection, HeaderFilter, DataGridTypes, Paging, Pager, Scrolling, LoadPanel } from 'devextreme-react/data-grid';

import { PlanningProps } from '../../../types/task';
import './TaskListGrid.scss';
import moment from 'moment';
export const TaskListGrid = React.forwardRef<DataGrid, PlanningProps>(({ dataSource, onPlanClick }, ref) => {
    const [data, setData] = useState<any[]>();
    const navigate = useNavigate();
    useEffect(() => {
        setData(dataSource);
    }, [dataSource]);

    const navigateToDetails = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
        navigate('/crm-contact-details', { state: { relationOid: data.Relation.Oid._value.toString(), backTo: 'taskList' } });
    }, []);

    const groupByMonth = (rowData) => {
        return moment(rowData.LastMaintenanceDate).format('yyyy-MM');
    };

    const renderGroupHeader = (data: any): JSX.Element => {
        return (
                <div style={{ fontSize: '14px', color: 'black' }}>{data.text}</div>
        );
    };

    return (
    <DataGrid
      className='planning-grid'
      ref={ref}
      dataSource={data}
      columnAutoWidth
      hoverStateEnabled
      height='100%'
      onRowClick={navigateToDetails}>
      <LoadPanel enabled={false} />
      <Scrolling mode='virtual' />
      <Paging defaultPageSize={15} />
      <Pager visible showPageSizeSelector />
      <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='none' mode='multiple' />
          <Column
              dataField='Relation.Name'
              caption='Relatie'
              hidingPriority={4}
          />
          <Column
              dataField='Relation.City'
              caption='Plaats'
              hidingPriority={4}
          />
          <Column
              dataField='ContractType'
              caption='Contract Type'
              hidingPriority={4}
          />
          <Column
              dataField='ContractStartDate'
              dataType='date'
              format='dd-MM-yyyy'
              caption='Contract Startdatum'
              hidingPriority={0}
          />
          <Column
              dataField='ContractEndPeriod'
              dataType='date'
              format='dd-MM-yyyy'
              caption='Contract Einddatum'
              hidingPriority={1}
          />
          <Column
               dataField='ContractPeriod'
              caption='Contract periode'
              hidingPriority={3}
            />
            <Column
                dataField='LastMaintenanceDate'
                dataType='date'
                format='dd-MM-yyyy'
                caption='Latste onderhoud'
                hidingPriority={4}
            />
            <Column
                dataField='LastMaintenanceDate'
                dataType='date'
                format='MMMM-yyyy'
                caption='Maand'
                groupIndex={0}
                groupCellRender={renderGroupHeader}
                calculateGroupValue={groupByMonth}
            />
            <Column dataField='MaintenancePlanned' caption='Onderhoud gepland' />
          <Column type='buttons' caption='Plannen' width={110}>
                    <Button icon='event' hint='Plan' onClick={onPlanClick} />
          </Column>
      </DataGrid>
  );
});
