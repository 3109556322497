
import React, { useState } from 'react';

import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { Relation } from '../../../types/crm-contact';
import { newRelation } from '../../../shared/constants';
import { FormTextbox } from '../..';
import { getSizeQualifier } from '../../../utils/media-query';

export const ContactNewForm = () => {
    const [newContactData, setNewContactData] = useState<Relation>(newRelation);

  const updateField = (field: string) => (value) => {
    setNewContactData((prevState) => ({ ...prevState, ...{ [field]: value } }));
  };

  return (
    <Form
      className='plain-styled-form'
      screenByWidth={getSizeQualifier}
    >
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label='Name'
            value={newContactData.Name}
            isEditing={false}
            onValueChange={updateField('Name')}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label='Last Company'
            value={newContactData.Company}
            isEditing={false}
            onValueChange={updateField('Company')}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label='ContactPerson'
            value={newContactData.ContactPerson}
            isEditing={false}
            onValueChange={updateField('ContactPerson')}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label='Position'
            value={newContactData.Email}
            isEditing={false}
            onValueChange={updateField('Email')}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};
