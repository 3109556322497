import axios from 'axios';

export async function getDashboardStatistics(token) {
    const res = await axios.get('/api/Dashboard/GetDashboardStatistics', getHeaders(token));
    try {
        return res.data;
    }
    catch {
        return res;
    }
}

export async function getServiceOverview(token) {
    const res = await axios.get('/api/Dashboard/GetServiceOverview', getHeaders(token));
    try {
        return res.data;
    }
    catch {
        return res;
    }
}

const getHeaders = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
};
