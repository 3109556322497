import React from 'react';
import logo from '../../../resources/logo.png';
import type { SingleCardProps } from '../../../types';

import './CardAuth.scss';

export const CardAuth = ({ title, description, children }: React.PropsWithChildren<SingleCardProps>) => {
  return (
    <div className='auth-card'>
      <div className='dx-card content'>
        <div className='header'>
            <div className='title'>
                <img src={logo} alt={title} />
            </div>
          <div className='description'>{ description }</div>
        </div>
        {children}
      </div>
    </div>
  );
};
