import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DataGrid, { Column, DataGridTypes, Editing } from 'devextreme-react/data-grid';
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';
import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store';
import './RevenueAnalysisCard.scss';
import { useAuth } from '../../../contexts/auth';
export const RevenueAnalysisCard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const dataSource = new DataSource({
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Report`,
            beforeSend: (e) => {
                e.headers = {
                    'Authorization': `Bearer ${user?.token}`
                };
            },
        },
        paginate: false,
        select: [
            'Oid',
            'TaskId',
            'Contract',
            'MoreWorkNeeded',
            'MaintenanceDate',
            'QuotationSent',
            'Remark'
        ],
        filter: ['MoreWorkNeeded', '=', true],
        sort: ['MaintenanceDate'],
        expand: ['Contract(expand=Relation)']
    });

    const navigateToDetails = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
        navigate('/crm-contact-details', { state: { relationOid: data.Contract.Relation.Oid._value.toString(), backTo: 'dashboard' } });
    }, []);

    return (
        <CardAnalytics title='Offerte aanvraag' contentClass='sales-by-state grid'>
        <DataGrid
            dataSource={dataSource}
            height={290}
            hoverStateEnabled
            onRowClick={navigateToDetails}>
            <Editing
                mode='cell'
                allowUpdating
                allowAdding={false}
                allowDeleting={false} />
            <Column caption='ID' dataType='number' width={70} allowEditing={false} alignment='left' dataField='TaskId' />
            <Column dataField='MaintenanceDate' width={100} allowEditing={false} dataType='date' format='dd-MM-yyyy' caption='Datum' />
            <Column caption='Relatie' allowEditing={false} dataField='Contract.Relation.Name' />
            <Column caption='Contract' allowEditing={false} dataField='Contract.ContractType' />
            <Column caption='Opmerking' allowEditing={false} dataField='Remark' />
            <Column caption='Offerte verzonden' dataField='QuotationSent' sortOrder='asc' />
            </DataGrid>
        </CardAnalytics>
    );
};
