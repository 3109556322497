export const navigation = [
    {
        text: 'Planning',
        icon: 'event',
        path: '',
        items: [
            {
                text: 'Agenda',
                path: '/planning-scheduler'
            },
            {
                text: 'Onderhoud overzicht',
                path: '/planning-task-list',
            },
        ],
    },
    {
    text: 'Dashboard',
    icon: 'chart',
    path: '/analytics-dashboard'
    },
  {
    text: 'Relaties',
    icon: 'user',
    path: '/crm-contact-list'
  },

  //{
  //  text: 'Account',
  //  icon: 'box',
  //  path: '',
  //  items: [
  //    {
  //      text: 'Profile',
  //      path: '/user-profile',
  //    }
  //  ]
  //},
];
