import './agenda-list-item.scss';
import React from 'react';

export const AgendaListItem = ({ item, resources }) => {
  return <div className='agenda-list-item'>
    <div className='description'>
      <div className='description-title'>
        {item.text}
      </div>
      <div className='description-resource'>
        {resources[item.calendarId]?.text}
      </div>
    </div>
  </div>;
};
