import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export async function getReportDocument(reportOid, token): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
        method: 'get',
        url: `/api/ReportDocument/GetDocument?reportOid=${reportOid}`,
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const response: AxiosResponse<Blob> = await axios(config);
        return response;
    } catch (error) {
        console.error('Error downloading file:', error);
        throw error;
    }
}

export async function getEmailReport(reportOid, token) {
    const res = await axios.get(`/api/ReportDocument/EmailReport?reportOid=${reportOid}`, getHeaders(token));
    try {
        return res.data;
    }
    catch {
        return res;
    }
}

const getHeaders = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
};

