import Query from 'devextreme/data/query';

export const findAllAppointmentsForMonth = (selectedAppointment, dataSource) => {
  if (!dataSource) {
    return [];
  }
  const appointments = dataSource.items();
  if (appointments.length === 0 || !selectedAppointment) {
    return [];
  }
  return Query(appointments)
    .filter((appointment) => {
        return new Date(appointment.startDate).getMonth() === new Date(selectedAppointment.startDate).getMonth();
    })
    .toArray();
};
