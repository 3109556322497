import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, refresh as refreshRequest } from '../api/auth';
import type { User, AuthContextType } from '../types';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      (async function() {
        let data: User = { isOk: false };
        await getUser().then(res =>{
            data = res;
        }).catch(error => {
            data = {
              isOk: false,
              message: error.response.data
            };
        });
      setUser(data);
      setLoading(false);
    })();
  }, []);

    const signIn = useCallback(async(email: string, password: string) =>{
        const result: User = await sendSignInRequest(email, password);
    if (await result.isOk) {
      setUser(result);
    }

    return result;
  }, []);

    const signOut = useCallback(async(refresh: boolean) =>{
        let response =
        {
            isOk: false
        };
        if (refresh === true) {
            response = await refreshRequest() as any;
        }

        if (refresh === false || response.isOk === false) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(undefined);
        }
        window.location.reload();
    }, []);

  return <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />;
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
