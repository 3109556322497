import moment from 'moment';
export const dataFilterOpen = [
    ['Relation', '<>', null],
    'and',
    [
        [
            [
                ['LastMaintenanceDate', '<=', new Date(moment().endOf('year').subtract(1, 'year').toISOString())],
                'or',
                ['LastMaintenanceDate', '=', null]
            ],
            'and',
            [
                ['MaintenancePlanned', '=', false],
                'or',
                ['MaintenancePlanned', '=', null]
            ],
            'and',
            ['ContractPeriod', '=', 'Month12']
        ],
        'or',
        [
            [
                ['LastMaintenanceDate', '<=', new Date(moment().endOf('month').subtract(1, 'month').toISOString())],
                'or',
                ['LastMaintenanceDate', '=', null]
            ],
            'and',
            [
                ['MaintenancePlanned', '=', false],
                'or',
                ['MaintenancePlanned', '=', null]
            ],
            'and',
            ['ContractPeriod', '=', 'Month1']
        ]
    ]
];

export const dataFilterPlanned = [
    ['Relation', '<>', null],
    'and',
    [
        [
            [
                ['LastMaintenanceDate', '<=', new Date(moment().endOf('year').subtract(1, 'year').toISOString())],
                'or',
                ['LastMaintenanceDate', '=', null]
            ],
            'and',
               ['MaintenancePlanned', '=', true],
            'and',
            ['ContractPeriod', '=', 'Month12']
        ],
        'or',
        [
            [
                ['LastMaintenanceDate', '<=', new Date(moment().endOf('month').subtract(1, 'month').toISOString())],
                'or',
                ['LastMaintenanceDate', '=', null]
            ],
            'and',
            ['MaintenancePlanned', '=', true],
            'and',
            ['ContractPeriod', '=', 'Month1']
        ]
    ]
];

export const dataFilterClosed = [
    ['Relation', '<>', null],
    'and',
    [
        [
           ['LastMaintenanceDate', '>=', new Date(moment().endOf('year').subtract(1, 'year').toISOString())],
            'and',
            ['ContractPeriod', '=', 'Month12']
        ],
        'or',
        [
            ['LastMaintenanceDate', '>=', new Date(moment().endOf('month').subtract(1, 'month').toISOString())],
            'and',
            ['ContractPeriod', '=', 'Month1']
        ]
    ]
];
