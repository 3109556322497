import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './crm-contact-list.scss';
import DataGrid, {
  Sorting, HeaderFilter, Scrolling, SearchPanel,
  Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { Relation } from '../../types/crm-contact';
import { FormPopup, ContactNewForm, ContactPanel } from '../../components';
import { useAuth } from '../../contexts/auth';
import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Contacts.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Contacts');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Contacts.xlsx');
      });
    });
    e.cancel = true;
  }
};

const exportFormats = ['xlsx', 'pdf'];

export const CRMContactList = () => {
    const [gridDataSource, setGridDataSource] = useState<DataSource<Relation[], string>>();
    const [isPanelOpened, setPanelOpened] = useState(false);
    const [contactId, setContactId] = useState<number>(0);
    const [popupVisible, setPopupVisible] = useState(false);
    const gridRef = useRef<DataGrid>(null);
    const navigate = useNavigate();
    const { user, signOut } = useAuth();
    const dataSource = new DataSource({
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: (e) => {
                e.headers = {
                    'Authorization': `Bearer ${user?.token}`
                };
            }
        },
        select: [
            'Oid',
            'CustomerId',
            'CustomerNumber',
            'Name',
            'ContactPerson',
            'Email',
            'InvoiceEmail',
            'Gender',
            'TelephoneNumber',
            'Address',
            'City',
            'ZipCode',
            'Company'
        ],
        expand: [],
    });

  useEffect(() => {
      setGridDataSource(dataSource);
  }, []);

  const changePopupVisibility = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance.option('focusedRowIndex', -1);
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance.updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const onRowClick = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
    setContactId(data.id);
    setPanelOpened(false);
      navigate('/crm-contact-details', { state: { relationOid: data.Oid._value.toString(), backTo: 'crmList' } });
  }, []);

  const refresh = useCallback(() => {
    gridRef.current?.instance.refresh();
  }, []);

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list'>
        <DataGrid
          className='grid'
          noDataText=''
          focusedRowEnabled
          height='100%'
          dataSource={gridDataSource}
          onRowClick={onRowClick}
          onExporting={onExporting}
          allowColumnReordering
          ref={gridRef}
        >
          <LoadPanel showPane={false} />
          <SearchPanel width={240} visible placeholder='Contact Search' />
          <Export allowExportSelectedData formats={exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Toolbar>
            <Item location='before'>
              <div className='grid-header'>Relaties</div>
            </Item>
            <Item location='after' locateInMenu='auto'>
             <Button
                visible={false}
                icon='plus'
                text='Add Contact'
                type='default'
                stylingMode='contained'
                onClick={onAddContactClick}
              />
            </Item>
            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto' />
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto' />
            <Item name='columnChooserButton' locateInMenu='auto' />
            <Item name='searchPanel' locateInMenu='auto' />
            </Toolbar>
          <Column dataField='CustomerNumber' alignment='left' width={70} caption='ID' />
          <Column sortOrder='asc' dataField='Name' caption='Naam' />
          <Column dataField='Company'caption='Bedrijf' />
          <Column dataField='ContactPerson' caption='Contact person' />
          <Column dataField='Email' caption='Email' />
          <Column dataField='TelephoneNumber' caption='Telefoon Number' />
          <Column dataField='City' caption='Vesteging' />
        </DataGrid>
        <ContactPanel contactId={contactId} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />
        <FormPopup title='Nieuwe Relatie' visible={popupVisible} setVisible={changePopupVisibility}>
          <ContactNewForm />
        </FormPopup>
      </div>
    </div>
  );
};
