import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import {
  ContactCards,
  ContactForm,
} from '../../components';

import { Relation } from '../../types/crm-contact';
import {
    getRelationByKey,
    getNotes
} from '../../api/relations';
import './crm-contact-details.scss';
import ScrollView from 'devextreme-react/scroll-view';
import { useAuth } from '../../contexts/auth';
import { Notes } from '../../types/card-notes';
export interface State {
    relationOid: string,
    backTo: string,
}
export const CRMContactDetails = () => {
const [data, setData] = useState<Relation>();
const state = useLocation().state as State;
const navigate = useNavigate();
const [notes, setNotes] = useState<Notes>();
const [notesCount, setNotesCount] = useState(0);
const [remarks, setRemarks] = useState<Notes>();
const [remarksCount, setRemarksCount] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const { user } = useAuth();
//const onMessagesCountChanged = useCallback((count) => {
//  setMessagesCount(count);
//}, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = useCallback(() => {
        Promise.all([
            getRelationByKey(state.relationOid, user?.token)
                .then((data) => {
                    setData(data);
                }),
            getNotes(state.relationOid, user?.token)
                .then((data) => {
                 const mappedData: Notes = data.map((item) => ({
                     text: item.Remark,
                     date: item.MaintenanceDate,
                     manager: item.DoneByName,
                     quotationSent: item.QuotationSent,
                     taskId: item.TaskId,
                     remarkDone: item.RemarkDone,
                     moreWorkNeeded: item.MoreWorkNeeded
                     // Map other properties as needed
                 }));
                    setNotes(mappedData.filter(obj => obj.moreWorkNeeded));
                    setNotesCount(data.filter(obj => !obj.QuotationSent && obj.MoreWorkNeeded).length);
                    setRemarks(mappedData.filter(obj => !obj.moreWorkNeeded && !isNullOrEmpty(obj.text)));
                    setRemarksCount(data.filter(obj => !obj.MoreWorkNeeded && !obj.RemarkDone && !isNullOrEmpty(obj.Remark)).length);
                })
    ]).then(() => { setIsLoading(false); }).catch((error) => console.log(error));
    }, []);

    function isNullOrEmpty(str) {
        return !str || str.trim() === '';
    }

    const refresh = useCallback(() => {
    setIsLoading(true);
    loadData();
    }, []);

    const goBackClick = useCallback(() => {
        if (state.backTo === 'taskList') {
            navigate('/planning-task-list');
        }
        else if (state.backTo === 'dashboard') {
            navigate('/analytics-dashboard');
        }
        else {
            navigate('/crm-contact-list');
        }
    }, []);

  return (
    <ScrollView className='view-wrapper-scroll'>
      <div className='view-wrapper view-wrapper-contact-details'>
        <Toolbar className='toolbar-details'>
             <Item location='before'>
               <Button icon='arrowleft' stylingMode='text' onClick={goBackClick} />
            </Item>
            <Item location='before' text={data?.Name ?? 'Loading...'} />
          <Item location='after' locateInMenu='auto'>
            <div className='separator' />
          </Item>
          <Item
            location='after'
            locateInMenu='auto'
            widget='dxButton'
            showText='inMenu'>
            <Button
              text='Refresh'
              icon='refresh'
              stylingMode='text'
              onClick={refresh}
            />
          </Item>
        </Toolbar>
        <div className='panels'>
          <div className='left'>
            <ContactForm
              data={data}
              isLoading={isLoading}
            />
          </div>
          <div className='right'>
            <ContactCards
                isLoading={isLoading}
                relationOid={state.relationOid}
                notes={notes}
                notesCount={notesCount}
                remarks={remarks}
                remarksCount={remarksCount}
               />
          </div>
        </div>
      </div>
    </ScrollView>
  );
};
