import React, { useCallback, useState, useEffect, useRef } from 'react';
import 'devextreme-react/text-area';
import Form, {
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    Item, GroupItem, Label, FormTypes,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { getMedewerkerList, getKlantenSimpel, createOpdracht, updateContract } from '../../../api/panning';
import { CONTRACTS_TYPE_LIST } from '../../../shared/constants';
import { MaintenanceTask } from '../../../types/task';
import { useAuth } from '../../../contexts/auth';
import notify from 'devextreme/ui/notify';
const OpdrachtForm = ({ contractOid, relationId, contractType, onComplete }) => {
    if (!relationId || !contractType) {
        return null;
    }
    const form = useRef<Form>(null);
    const [relationData, setRelationData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [maintenanceTask, setMaintenanceTask] = useState<MaintenanceTask>({});
    const { user } = useAuth();
    useEffect(() => {
        getKlantenSimpel(user?.tokenKlikPlatform).then(res => setRelationData(res));
        getMedewerkerList(user?.tokenKlikPlatform).then(res => setEmployeeData(res));
    }, []);
    const [loading, setLoading] = useState(false);

    const validateForm = useCallback((e: FormTypes.ContentReadyEvent) => {
        e.component.validate();
    }, []);
    const relationEditorOptions = { items: relationData, searchEnabled: false, readOnly: true, value: relationId, valueExpr: 'id_klant', displayExpr: 'naam' };
    const maintenanceEditorOptions = { items: CONTRACTS_TYPE_LIST, searchEnabled: false, readOnly: true, value: contractType };
    const employeeEditorOptions = { dataSource: employeeData, searchEnabled: true, disabled: false, valueExpr: 'id_werknemer', displayExpr: 'naam' };
    const notesEditorOptions = { height: 90, maxLength: 200 };
    const onSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        let response: any = null;
        await createOpdracht(user?.tokenKlikPlatform, maintenanceTask.opdracht_omschrijving, maintenanceTask.opdracht_opmerkingen,
            maintenanceTask.opdracht_datum, maintenanceTask.werkzaamheden, maintenanceTask.status, maintenanceTask.medewerker, maintenanceTask.klant, maintenanceTask.opdracht_allday, true, maintenanceTask.opdracht_einddatum, contractOid).then((result) => {
                if (result.status === 200) {
                    response = result.data.opdracht;
                    notify('Opdracht is aangemaakt', 'success', 2000);
                    setLoading(false);
                    if (form.current) {
                        form.current.instance.resetValues();
                        onComplete();
                    }
            }

            }).catch(error => {
                notify(error.response.data, 'error', 2000);
            });
        await updateContract(user?.token, contractOid, (response !== null), response.opdracht_datum).then((result) => {
                if (result.status === 200) {
                    // notify("Formulier is opgeslagen", 'success', 2000);
                    setLoading(false);
                }

            }).catch(error => {
                notify(error.response.data, 'error', 2000);
            });
        setLoading(false);
    };

    const formDataChanged = (e) => {
        setMaintenanceTask({ ...maintenanceTask, [e.dataField]: e.value });
    };

    return (
        <form onSubmit={onSubmit}>
            <Form ref={form} formData={maintenanceTask} onFormDataChange={formDataChanged} onContentReady={validateForm}>
            <GroupItem>
                    <Item dataField='klant' editorType='dxSelectBox' editorOptions={relationEditorOptions}>
                    <Label text='Relatie' />
                </Item>
                    <Item dataField='werkzaamheden' editorType='dxSelectBox' editorOptions={maintenanceEditorOptions}>
                    <Label text='Onderhoud soort' />
                    </Item>
                    <GroupItem colCount={2}>
                        <Item dataField='opdracht_datum' editorOptions={{ type: 'datetime', displayFormat:'dd-MM-yyyy HH:mm' }} editorType='dxDateBox'>
                            <Label text='Start datum' />
                            <RequiredRule message='Start datum is verplicht' />
                        </Item>
                        <Item dataField='opdracht_einddatum' editorOptions={{ type: 'datetime', displayFormat:'dd-MM-yyyy HH:mm' }} editorType='dxDateBox'>
                            <Label text='End date' />
                            <RequiredRule message='Eind datum is verplicht' />
                        </Item>
                    </GroupItem>
                    <Item dataField='medewerker' editorType='dxSelectBox' editorOptions={employeeEditorOptions}>
                        <Label text='Medewerker' />
                    <RequiredRule message='Medewerker is verplicht' />
                </Item>
                    <Item dataField='opdracht_omschrijving'>
                    <Label text='Opdracht' />
                        <RequiredRule message='Opdracht titel is verplicht' />
                </Item>
                    <Item dataField='opdracht_opmerkingen' colSpan={2} editorType='dxTextArea' editorOptions={notesEditorOptions}>
                    <Label text='Opmerkingen' />
                </Item>
            </GroupItem>
            <ButtonItem>
                <ButtonOptions
                    width='25%'
                    type='default'
                    useSubmitBehavior
                >
                    <span className='dx-button-text'>
                        {
                            loading
                                ? <LoadIndicator width='24px' height='24px' visible />
                                : 'Opslaan'
                        }
                    </span>
                </ButtonOptions>
            </ButtonItem>
            </Form>
        </form>
    );
};

export { OpdrachtForm };
