import React from 'react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';

import {
 CardActivities,
  CardNotes,
 // CardMessages,
  CardTasks,
  //CardOpportunities
} from '../..';

export const ContactCards = ({
  isLoading,
   relationOid,
  //activities,
  //name,
  //activeOpportunities,
  //closedOpportunities,
    notes,
    notesCount,
    remarks,
    remarksCount,
  ///messages,
  //onMessagesCountChanged
}) => {
  return (
    <div className='dx-card details-card'>
      <TabPanel showNavButtons deferRendering={false}>
        <TabPanelItem title='Contracten'>
          <CardTasks
            isLoading={isLoading}
            relationOid={relationOid}
          />
        </TabPanelItem>
        <TabPanelItem title='Documenten'>
            <CardActivities relationOid={relationOid} />
        </TabPanelItem>
        {/*<TabPanelItem title='Activities'>*/}
        {/*  <CardActivities activities={activities} isLoading={isLoading} />*/}
        {/*</TabPanelItem>*/}
        {/*<TabPanelItem title='Opportunities'>*/}
        {/*  <CardOpportunities*/}
        {/*    active={activeOpportunities}*/}
        {/*    closed={closedOpportunities}*/}
        {/*  />*/}
        {/*</TabPanelItem>*/}
        <TabPanelItem title='Offerte aanvraag' badge={notesCount}>
            <CardNotes items={notes} />
        </TabPanelItem>
        <TabPanelItem title='Opmerkingen' badge={remarksCount}>
            <CardNotes items={remarks} />
        </TabPanelItem>
      </TabPanel>
    </div>
  );
};
