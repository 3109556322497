import React, { useCallback, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/toolbar';
import Tabs from 'devextreme-react/tabs';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';
import { useScreenSize } from '../../utils/media-query';
import { useAuth } from '../../contexts/auth';
import { getDashboardStatistics } from '../../api/dashboard';
import {
  ToolbarAnalytics,
    RevenueAnalysisCard,
    RemarksCard,
  Ticker
} from '../../components';
import { ANALYTICS_PERIODS, DEFAULT_ANALYTICS_PERIOD_KEY } from '../../shared/constants';
import './analytics-dashboard.scss';

const items = Object.keys(ANALYTICS_PERIODS);

export const AnalyticsDashboard = () => {
    const [tabIndex, setTabIndex] = useState(ANALYTICS_PERIODS[DEFAULT_ANALYTICS_PERIOD_KEY].index);
    const [dateRange, setDateRange] = useState(ANALYTICS_PERIODS[DEFAULT_ANALYTICS_PERIOD_KEY].period.split('/'));
    const [isLoading, setIsLoading] = useState(true);
    const [tabsWidth, setTabsWidth] = useState<number | string>('auto');
    const [total, setTotal] = useState(0);
    const [closed, setClosed] = useState(0);
    const [open, setOpen] = useState(0);
    const [planned, setPlanned] = useState(0);

    const { user } = useAuth();
    const { isXSmall } = useScreenSize();

    useEffect(() => {
        Promise.all([
            getDashboardStatistics(user?.token).then((data) => {
                setTotal(data.totalContracts);
                setClosed(data.closedContracts);
                setOpen(data.openContracts);
                setPlanned(data.plannedContracts);
            }),
        ])
            .then(() => setIsLoading(false))
            .catch((error) => console.log(error));
    }, [dateRange]);

    const onTabClick = useCallback((e) => {
        const { index, period } = ANALYTICS_PERIODS[e.itemData];
        setTabIndex(index);
        setDateRange(period.split('/'));
        setIsLoading(true);
    }, []);

    useEffect(() => {
        setTabsWidth(isXSmall ? 150 : 'auto');
    }, []);

    return (
        <ScrollView className='view-wrapper-scroll'>
            <ToolbarAnalytics
                title='Dashboard'
                additionalToolbarContent={
                    <Item
                        location='before'>
                        <Tabs
                            visible={false}
                            width={tabsWidth}
                            scrollByContent
                            showNavButtons={false}
                            dataSource={items}
                            selectedIndex={tabIndex}
                            onItemClick={onTabClick} />
                    </Item>
                }>
                <div className='cards compact'>
                    <Ticker name='Niet Gepland' value={open} />
                    <Ticker name='Gepland' value={planned} />
                    <Ticker name='Afgerond' value={closed} />
                    <Ticker name='Totaal' value={total} />
                </div>
                <div className='cards normal'>
                    <RevenueAnalysisCard />
                    <RemarksCard />
                </div>
            </ToolbarAnalytics>
            <LoadPanel container='.content' visible={isLoading} position={{ of: '.layout-body' }} />
        </ScrollView>
    );
};
