import React, { useState, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useAuth } from '../../../contexts/auth';

import './LoginForm.scss';

export const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(loadFormData());
  const onSubmit = useCallback(
      async(e) => {
      e.preventDefault();
      setLoading(true);
      const result = await signIn(formData.email, formData.password);
      if (result.isOk) {
        navigate('/');
      } else {
        setLoading(false);
        notify(result.message, 'error', 2000);
      }
    },
    [signIn, formData]
  );

    function loadFormData() {
        if ('authObject' in localStorage) {
            const storedData = localStorage.getItem('authObject');
            return storedData ? JSON.parse(storedData) : null;
        }
        else {
            return { email: '', password: '', rememberMe: false };
        }
    }

    const onFieldDataChanged = (e) => {
        switch (e.dataField) {
            case 'rememberMe':
                if (e.value) {
                    localStorage.setItem('authObject', JSON.stringify(formData));
                }
                else {
                    localStorage.removeItem('authObject');
                }
                break;
            case 'email':
                setFormData(prevState => {
                    return { ...prevState, emmail: e.value };
                });
                if (formData.rememberMe) { localStorage.setItem('authObject', JSON.stringify(formData)); }
                break;
            case 'password':
                setFormData(prevState => {
                    return { ...prevState, password: e.value };
                });
                if (formData.rememberMe) { localStorage.setItem('authObject', JSON.stringify(formData)); }
                break;
        }
    };

  return (
    <form className='login-form' onSubmit={onSubmit}>
          <Form
        formData={formData}
        disabled={loading}
        showColonAfterLabel
        showRequiredMark={false}
        onFieldDataChanged={onFieldDataChanged}
      >
        <Item dataField='email' editorType='dxTextBox' editorOptions={emailEditorOptions}>
          <RequiredRule message='Email is required' />
          <Label visible={false} />
        </Item>
        <Item dataField='password' editorType='dxTextBox' editorOptions={passwordEditorOptions}>
          <Label visible={false} />
        </Item>
        <Item dataField='rememberMe' editorType='dxCheckBox' editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width='100%' type='default' useSubmitBehavior>
            <span className='dx-button-text'>{loading ? <LoadIndicator width='24px' height='24px' visible /> : 'Sign In'}</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      {/*<div className='reset-link'>*/}
      {/*  <Link to={resetLink}>Forgot password?</Link>*/}
      {/*</div>*/}
    </form>
  );
};

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
