import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridXSLX } from 'devextreme/excel_exporter';
import LoadPanel from 'devextreme-react/load-panel';
import { TaskListGrid } from '../../components';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './planning-task-list.scss';
import { dataFilterClosed, dataFilterPlanned, dataFilterOpen } from './taskFilters';
import Button from 'devextreme-react/button';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store';
import { useAuth } from '../../contexts/auth';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { OpdrachtForm } from '../../components/library/opdracht-form/opdrachtForm';

const listsData = ['List'];
type FilterContactStatus = 'All';
const filterStatusList = ['All', 'Open', 'Planned', 'Closed'];
export const PlanningTaskList = () => {
    const { user, signOut } = useAuth();
    const gridRef = useRef<DataGrid>(null);
    const [listView] = listsData;
    const [view] = useState(listView);
    const [gridData, setGridData] = useState<any>([]);
    const [loading] = useState(false);
    const dropDownOptions = { width: 'auto' };
    const isDataGrid = view === listView;
    const [addTaskPopupOpen, setAddTaskPopupOpen] = useState<boolean>(false);
    const [taskData, setTaskData] = useState<[number, string, string]>([0, '1', '2']);
    const dataSource = new DataSource({
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Contract`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: (e) => {
                e.headers = {
                    'Authorization': `Bearer ${user?.token}`
                };
            },
        },
        paginate: false,
        select: [
            'Oid',
            'ContractType',
            'ContractStartDate',
            'ContractEndPeriod',
            'AutoRenewContract',
            'LastMaintenanceDate',
            'MaintenancePlanned',
            'ContractPeriod',
            'Relation'
        ],
        sort: ['LastMaintenanceDate'],
        expand: ['Relation'],
        filter: dataFilterOpen,
    });
    useEffect(() => {
        setGridData(dataSource);
    }, []);

    const refresh = useCallback(() => {
    if (isDataGrid) {
        gridRef.current?.instance.refresh();
    }
    }, [view]);

    const showColumnChooser = useCallback(() => {
    gridRef.current?.instance.showColumnChooser();
    }, []);

    const exportToPDF = useCallback(() => {
    if (isDataGrid) {
        const doc = new jsPDF();
        exportDataGrid({
        jsPDFDocument: doc,
        component: gridRef.current?.instance,
        }).then(() => {
        doc.save('Tasks.pdf');
        });
    }
    }, [view]);

    const exportToXSLX = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGridXSLX({
        component: gridRef.current?.instance,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    });
    }, []);

    const search = useCallback((e: TextBoxTypes.InputEvent) => {
    gridRef.current?.instance.searchByText(e.component.option('text') ?? '');
    }, []);

    const [status, setStatus] = useState(filterStatusList[1]);

    const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
        const { item: status }: { item: FilterContactStatus } = e;
        console.log(gridRef.current?.instance);
        if (status === 'All') {
            gridRef.current?.instance.filter(['Relation', '<>', null]);
            gridRef.current?.instance.clearSorting();
        } else if (status === 'Open') {
            gridRef.current?.instance.filter(dataFilterOpen);
        } else if (status === 'Planned') {
            gridRef.current?.instance.filter(dataFilterPlanned);
        } else if (status === 'Closed') {
            gridRef.current?.instance.filter(dataFilterClosed);
        }

        setStatus(status);
        gridRef.current?.instance.state(null);
    }, []);

    const onPlanClick = useCallback((data) => {
        setAddTaskPopupOpen(true);
        setTaskData([data.row.data.Relation.CustomerId, data.row.data.ContractType, data.row.data.Oid._value.toString()]);
    }, []);

    const addTaskHidePopup = useCallback(() => {
        setAddTaskPopupOpen(false);
    }, []);

    const hideInfo = () => {
        setAddTaskPopupOpen(false);
    };

    const closeButtonOptions = {
        text: 'Sluiten',
        onClick: hideInfo
    };

  return (
    <div className='view-wrapper view-wrapper-task-list'>
      <Toolbar className='toolbar-common'>
        <Item location='before'>
          <span className='toolbar-header'>Contracten</span>
        </Item>
        <Item location='before'>
            <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={status}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
            />
        </Item>
        <Item
          location='after'
          widget='dxButton'
          showText='inMenu'
          locateInMenu='auto'
        >
          <Button
            icon='refresh'
            text='Refresh'
            stylingMode='text'
            onClick={refresh}
          />
        </Item>
        <Item
          location='after'
          widget='dxButton'
          showText='inMenu'
          locateInMenu='auto'
          disabled={view !== listView}
        >
          <Button
            icon='columnchooser'
            text='Column Chooser'
            stylingMode='text'
            onClick={showColumnChooser}
          />
        </Item>
        <Item location='after' locateInMenu='auto'>
          <div className='separator' />
        </Item>
        <Item
          location='after'
          widget='dxButton'
          showText='inMenu'
          locateInMenu='auto'
        >
          <Button
            icon='exportpdf'
            text='Export To PDF'
            stylingMode='text'
            onClick={exportToPDF}
          />
        </Item>
        <Item
          location='after'
          widget='dxButton'
          showText='inMenu'
          locateInMenu='auto'
          disabled={view !== listView}
        >
          <Button
            icon='exportxlsx'
            text='Export To XSLX'
            stylingMode='text'
            onClick={exportToXSLX}
          />
        </Item>
        <Item
          location='after'
          widget='dxTextBox'
          locateInMenu='auto'
          disabled={view !== listView}
        >
          <TextBox
            mode='search'
            placeholder='Zoeken'
            onInput={search}
          />
        </Item>
      </Toolbar>
          {loading && <LoadPanel container='.content' showPane={false} visible position={{ of: '.content' }} />}
          {!loading && isDataGrid && <TaskListGrid dataSource={gridData} onPlanClick={onPlanClick} ref={gridRef} />}
          <Popup
              visible={addTaskPopupOpen}
              onHiding={addTaskHidePopup}
              dragEnabled={false}
              hideOnOutsideClick
              showCloseButton={false}
              showTitle
              title='Opdracht aanmaken'
              width={600}
              height={650}>
              <ToolbarItem
                  widget='dxButton'
                  toolbar='top'
                  location='after'
                  options={closeButtonOptions} />
              <OpdrachtForm contractOid={taskData[2]} relationId={taskData[0]} contractType={taskData[1]} onComplete={hideInfo} />
          </Popup>
    </div>
  );
};
