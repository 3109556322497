import { Task } from '../types/task';
import { Relation, Contact } from '../types/crm-contact';

export const PRIORITY_ITEMS = ['Low', 'Normal', 'High'];
export const STATUS_ITEMS = ['Open', 'InProgress', 'Deferred', 'Completed'];

export const ANALYTICS_PERIODS = {
  Week: {
    period: '2020-01-24/2020-01-31',
    index: 0,
  },
  '2 Weeks': {
    period: '2020-01-14/2020-01-31',
    index: 1,
  },
  Month: {
    period: '2020-01-01/2020-02-01',
    index: 2,
  },
  Year: {
    period: '2020-01-01/2021-01-01',
    index: 3,
  },
  All: {
    period: '2018-01-01/2022-01-01',
    index: 4,
  },
};

export const DEFAULT_ANALYTICS_PERIOD_KEY = 'All';

export const CONTACT_STATUS_LIST = [
  'Salaried',
  'Commission',
  'Terminated',
];

export const CONTRACTS_TYPE_LIST = ['GeneralService', 'CameraService', 'TgcAndIcService', 'FireMaintenance', 'AlarmService'];
export const CONTRACT_PERIOD_LIST = ['Month1', 'Month12'];

export const newTask: Task = {
  id: 0,
  text: '',
  description: '',
  company: '',
  priority: 'Low',
  startDate: new Date(),
  dueDate: new Date(),
  owner: '',
  status: 'Open',
  activities: [],
  notes: [],
  messages: [],
  parentId: 0,
  progress: 0,
};

export const newRelation: Relation = {
    Oid:'',
    CustomerId: 0,
    CustomerNumber: 0,
    Name: '',
    ContactPerson: '',
    Email: '',
    InvoiceEmail: '',
    Gender: 0,
    TelephoneNumber: '',
    Address: '',
    City: '',
    ZipCode: '',
    Company: '',
    Contracts:[]
};

export const newContact: Contact = {
    id: 0,
    name: '',
    address: '',
    firstName: '',
    lastName: '',
    status: 'Salaried',
    position: '',
    manager: '',
    company: '',
    city: '',
    state: {
        stateShort: '',
    },
    zipCode: 0,
    phone: '',
    email: '',
    image: '',
    activities: [],
    opportunities: [],
    tasks: [],
};

export const contractTypesResources = [
    {
        key: 'Contract Types',
        items: [
            {
                id: 0,
                text: 'Algemene Onderhoud',
                color: '#B3E5FC',
                checkboxColor: '#29B6F6',
            },
            {
                id: 1,
                text: 'Camera Onderhoud',
                color: '#C8E6C9',
                checkboxColor: '#66BB6A',
            },
            {
                id: 2,
                text: 'Tgc And Ic Onderhoud',
                color: '#FFCDD2',
                checkboxColor: '#EF5350',
            },
            {
                id: 3,
                text: 'Brand Onderhoud',
                color: '#FFE0B2',
                checkboxColor: '#FFA726',
            },
            {
                id: 4,
                text: 'Alarm Onderhoud',
                color: '#f08494',
                checkboxColor: '#f08494',
            }],
    },
];
