import React from 'react';
import List from 'devextreme-react/list';
import { DropDownButton } from 'devextreme-react/drop-down-button';
import { formatDate } from 'devextreme/localization';
import classNames from 'classnames';
import { withLoadPanel } from '../../../utils/withLoadPanel';
import DataSource from 'devextreme/data/data_source';
import Guid from 'devextreme/core/guid';
import 'devextreme/data/odata/store';
import './CardActivities.scss';
import '../card-menu/CardMenu.scss';
import { useAuth } from '../../../contexts/auth';
import { getReportDocument, getEmailReport } from '../../../api/contract';
import notify from 'devextreme/ui/notify';
import { AxiosResponse } from 'axios';
const activityMenuItems = [
    { text: 'View report' },
    { text: 'Email report' }
];

export const CardActivities = ({ relationOid, isLoading }: { relationOid?: string, isLoading?: boolean }) => {
    const { user } = useAuth();
    const ActivitiesList = ({ relationOid }) => {
        const dataSource = new DataSource({
            store: {
                type: 'odata',
                version: 4,
                key: 'Oid',
                keyType: 'Guid',
                url: `${process.env.REACT_APP_URL}/api/odata/Report`,
                beforeSend: (e) => {
                    e.headers = {
                        'Authorization': `Bearer ${user?.token}`
                    };
                },
                onInserting: (values) => {
                    values['RelationOid'] = relationOid;
                }
            },
            paginate: false,
            select: [
                'Oid',
                'TaskId',
                'MaintenanceDate',
                'Status',
                'DoneByName',
                'FileName',
                'Contract'
            ],
            filter: ['Contract/Relation/Oid', '=', new Guid(relationOid)],
            expand: ['Contract($expand=Relation)'],
        });

        const listItemRender = (item: any) => {
            const dropDownAction = async(e) => {

                if (e.itemData.text === 'View report') {
                    try {
                        const fileBlob: AxiosResponse<Blob> = await getReportDocument(item.Oid._value, user?.token);
                        const downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(fileBlob.data);
                        const fileName = fileBlob.headers['content-disposition'].replace('inline; filename=', '');
                        downloadLink.download = fileName;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    } catch (error) {
                        notify(e.message, 'error', 2000);
                        console.error('Error fetching file:', error);
                    }
                } else if (e.itemData.text === 'Email report')
                {
                    getEmailReport(item.Oid._value, user?.token).then((data) =>
                    {
                        //console.log(data);
                        window.location.href = data;
                    }).catch ((error) => console.log(error));
                }
            };
            return (
                <div className='activity'>
                    <div className='name'>{item.TaskId} - {item.Contract.ContractType}</div>
                    <div className='date by'>
                        <span>{formatDate(new Date(item.MaintenanceDate), 'dd-MM-yyyy')}</span>
                        <span className='by-span'>by</span>
                        <span>{item.DoneByName}</span>
                    </div>
                    <DropDownButton
                        onItemClick={dropDownAction}
                        className='overflow-menu'
                        items={activityMenuItems}
                        visible
                        icon='overflow'
                        stylingMode='text'
                        showArrowIcon={false}
                        dropDownOptions={{ width: 'auto' }}
                    />
                </div>
            );
        };

        return (
            <List className='activities-list' dataSource={dataSource} scrollingEnabled={false} itemRender={listItemRender} />
        );
    };

    const ActivitiesWithLoadPanel = withLoadPanel(ActivitiesList);
    return (
        <div className={classNames({ 'card-activities': true, load: false })}>
            <ActivitiesWithLoadPanel
                relationOid={relationOid}
                hasData={!!relationOid}
                loading={isLoading}
                panelProps={{
                    container: '.card-activities',
                    position: { of: '.card-activities' }
                }}
            />
        </div>
    );
};
