import React from 'react';

import { TickerCard } from '../../library/ticker-card/TickerCard';
import { CounterTickerProps } from '../../../types/analytics';

export const Ticker = ({ value, name }: CounterTickerProps) =>
    <TickerCard
        contentClass='leads'
        title={name}
        value={value}
    />;
