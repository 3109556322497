import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';

import { Template } from 'devextreme-react/core/template';
import logo from '../../../resources/logo.png';
import { UserPanel } from '../user-panel/UserPanel';

import type { AppHeaderProps } from '../../../types';

import './AppHeader.scss';

export const AppHeader = ({ menuToggleEnabled, title, toggleMenu, className }: AppHeaderProps) => {
  return (
    <header className={`header-component ${className}`}>
      <Toolbar className='header-toolbar'>
        <Item visible={menuToggleEnabled} location='before' widget='dxButton' cssClass='menu-button'>
          <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
        </Item>
        <Item location='before' cssClass='header-title'>
          <img width='150px' height='45px' src={logo} alt={title} />
              </Item>
              <Item visible={false} location='after'>
          <div className='messages'>
            <Button icon='belloutline' stylingMode='text' />
            <div className='dx-badge'>
              4
            </div>
          </div>
        </Item>
        <Item location='after' locateInMenu='auto' menuItemTemplate='userPanelTemplate'>
          <UserPanel menuMode='context' />
        </Item>
        <Template name='userPanelTemplate'>
          <UserPanel menuMode='list' />
        </Template>
      </Toolbar>
    </header>
  );
};
